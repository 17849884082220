import React from "react"
import CongratsPage from "../components/onboarding/CongratsPage"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import dots from "../images/bgs/dots.svg"


const StudentCongrats = () => (
  <Layout image= {dots} full>
    <SEO title="Congrats!" />
    <CongratsPage
      congratsMessage="You are now a registered volunteer for RemoteTA"
      teacher={false}
      buttonName="Start Volunteering"
    />
  </Layout>
)

export default StudentCongrats
